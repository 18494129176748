import React from 'react'
import Layout from '../../components/layout'
import { StaticQuery, graphql } from 'gatsby'

import Nav from '../../components/cn/nav'

import Divider from '../../images/divider.png'

const ExpertisePage = () => (
  <StaticQuery
    query={graphql`
      query CNExpertiseQuery {
        wordpressPage(wordpress_id: { eq: 145 }) {
          acf {
            circle_image {
              source_url
            }
            chinese
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Nav />
        <div className="app-main body-main">
          <div
            className="top-image"
            style={{
              backgroundImage: `url(${Divider})`,
            }}
          >
            <img
              alt=""
              className="circle"
              src={data.wordpressPage.acf.circle_image.source_url}
            />
          </div>
          <h1>阿姆斯壮在全球医疗方面的专业知识</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.acf.chinese,
            }}
          />
        </div>
      </Layout>
    )}
  />
)

export default ExpertisePage
